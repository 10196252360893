import { Image } from "shared/components/atoms/Image";
import { CSSProp } from "styled-components";
import { styled } from "twin.macro";

const Title = styled.div`
  font-family: ヒラギノ明朝 ProN;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #fff;
  text-align: center;
  white-space: normal;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.23px;
  color: #fff;
  text-align: center;
  white-space: normal;
`;

interface Props {
  src: string;
  width: number;
  height: number;
  title?: string;
  subTitle?: string;
  classes?: {
    title: CSSProp;
  };
  onClick?: () => void;
}

export const ImageCard = ({
  src,
  width,
  height,
  title,
  subTitle,
  classes,
  onClick,
}: Props) => {
  return (
    <button
      type="button"
      tw="flex items-center justify-center relative overflow-hidden"
      onClick={onClick}
    >
      <Image
        tw="rounded-xl"
        src={src}
        width={width}
        height={height}
        layout="fixed"
        objectFit="cover"
      />
      <div tw="absolute w-full p-2 box-border">
        {title && (
          <Title tw="mb-1" css={classes?.title}>
            {title}
          </Title>
        )}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </div>
    </button>
  );
};
