import React, { FC } from "react";

import { ClockIcon } from "public/media/icons";
import { ReactNode } from "react";

export const LastModLabel: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div tw="flex items-end items-center">
      <ClockIcon />
      <div tw="font-base text-[11px] tracking-[.06px] ml-[5px]">{children}</div>
    </div>
  );
};
