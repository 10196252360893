import { FC } from "react";

interface Props {
  className?: string;
}

export const PinkyRectangle: FC<Props> = ({ children, className }) => {
  return (
    <div tw="py-5 bg-[#fff1fb]" className={className}>
      {children}
    </div>
  );
};
